<template>
    <div>
        <v-dialog
                v-model="dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                   Уведомления
                </v-card-title>
                <v-card-text>{{ dialogText }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete"  persistent
                  max-width="900">
            <v-card>
                <v-card-title class="text-h5">Загрузить Excel</v-card-title>
                <v-card-actions>
                    <v-btn color="primary" depressed @click="close">Отмена</v-btn>
                    <v-btn color="success" depressed @click="confirm">Загрузить</v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-container>
                        <v-autocomplete
                                v-model="type"
                                :items="typeItemsUpload"
                                item-text="name"
                                label="Тип файла"
                                outlined
                                return-object
                        ></v-autocomplete>
                        <v-file-input
                                v-model="file"
                                label="Файл"
                                outlined
                        ></v-file-input>
                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>
        <app-list-table
                ref="listTable"
                :props-model="propsModel"
                :props-data-request="propsDataRequest"
                :props-filter="propsFilter"
        >
            <template v-slot:header>
                <v-btn
                        style="margin-left: 10px"
                        color="warning"
                        dark
                        class="mb-2"
                        v-on:click="showDialog"
                >
                    Загрузить
                </v-btn>
            </template>
        </app-list-table>
    </div>
</template>

<script>
    import AppListTable from "../../widget/AppListTable";
    import {apiBlank} from "../../../api/api-blank";
    import http from "../../../api/http-common";
    import store from "../../../store";
    import {apiDirectory} from "../../../api/api-directory";
    import Swal from 'sweetalert2';

    export default {
        name: "ListTableExcel",
        components: {
            AppListTable,
        },
        props: {
            propsModel: String,
            propsDataRequest: String,
            propsFilter: Object,
        },
        data: () => ({
            type: null,
            file: null,
            dialog: false,
            dialogText: '',
            dialogDelete: false,
            typeItemsDownload: [
                { name: 'Ошибка загрузки', id: 99 },
            ],
            typeItemsUpload: [
                { name: 'Ошибка загрузки', id: 99 },
            ],
        }),
        created() {
            this.apiBlank = new apiBlank(this.propsModel);
            this.apiDirectory = new apiDirectory(this.propsModel, this.propsDataRequest);
            this.init();
        },
        methods: {
            async init() {
                let model = await this.apiDirectory.getFileType(this.propsDataRequest);
                if ((typeof model.data.upload !== "object") && (typeof model.data.download !== "object"))
                    return Swal.fire(
                        "Ошибка",
                        `В документе нет объектов со значением
                         ${(typeof model.data.download !== "object") ? 'Отсутствует файл загрузки (download)' : ''}
                         ${(typeof model.data.upload !== "object") ? 'Отсутствует файл отправки (upload)' : ''}`,
                        'error'
                    );
                else {
                    this.typeItemsDownload = [];
                    this.typeItemsUpload = [];
                    for (const [key, value] of Object.entries(model.data.download)) {
                        this.typeItemsDownload.push({
                            name: value,
                            id: key
                        });
                    }
                    for (const [key, value] of Object.entries(model.data.upload)) {
                        this.typeItemsUpload.push({
                            name: value,
                            id: key
                        });
                    }
                }
            },
            showDialog() {
                this.dialogDelete = true;
                this.file = null;
                this.type = null;
            },
            close() {
                this.dialogDelete = false
            },
            confirm() {
                this.dialogDelete = false
                console.log(this.type);
                console.log(this.file);
                this.submitFiles(this.file, this.type.id)
            },
            submitFiles(file, id) {
                let formData = new FormData();
                formData.append("File", file, file.name);
                formData.append("FileType", id);
                http
                    .post("/UploadFile", formData, {
                        headers: {
                            'Authorization': `Bearer ${store.getters.token}`
                        }
                    })
                    .then(response => {
                        console.log("Success!");
                        this.dialog = true;
                        this.dialogText = 'Загрузка прошла успешно';
                        console.log({ response });
                        this.$refs.listTable.initialize(this.propsModel, this.propsDataRequest).then(() => {});
                    })
                    .catch(error => {
                        let errors = "Неизвестная ошибка";
                        if (error.response.data.errors != null) {
                            Object.keys(error.response.data.errors).forEach(key => {
                                error.response.data.errors[key].forEach(item => {
                                    if (errors === "Неизвестная ошибка") errors = "";
                                    errors += `[${key}] - ` + item + "\n";
                                });
                            });
                        }
                        if (error.response.data.title != null) {
                            errors = error.response.data.title;
                        }
                        this.dialogText = errors;
                        this.dialog = true;
                        console.log({ error });
                    });
            }
        }
    }
</script>

<style scoped>

</style>