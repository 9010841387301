import store from "@/store";
import http from "./http-common";
import Swal from "sweetalert2";
import GeneralAPI from ".";

/**
 * @param {apiAttachment} apiAttachment
 */
export class apiAttachment extends GeneralAPI {
  _basicAuth = ''

  constructor() {
    super()
    this._basicAuth = `Bearer ${store.getters.token}`;
  }

  static parseStringToArray(value) {
    let newObject = [];
    let arrayValue = value.split(';')
    arrayValue.forEach(item => {
      let objectItem = item.split('=')
      newObject.push({ name: objectItem[1], id: objectItem[0] });
    })
    return newObject;
  }

  static b64toBlob(b64Data, contentType = '', fileName) {
    const imageContent = atob(b64Data);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = contentType;
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  }

  /**
   * Получение метаданных об объекте данных
   */
  getModel() {
    return http.get(`/utils/Metadata/BaseAttachmentModel`, {
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }

  /**
   * Получить список типов вложения
   * @param section 0 - photos, 1 - documents
   */
  getAttachmentTypes(section) {
    return http.get('/utils/AttachmentTypes/' + section).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    })
  }

  /**
   * Получить список файлов для скачивания
   */
  getAttachmentList(id, SectionType) {
    return http.post(`/Attachment/list`, {
      page: 1,
      rows: 1000,
      filter: [
        {
          "field": "SectionType",
          "operator": 0,
          "value": SectionType
        },
        {
          "field": "ClaimId",
          "operator": 0,
          "value": id
        }
      ]
    }, {
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }
  /**
   * Получить миниатюру
   * @param id
   */
  getAttachmentThumb(id) {
    return http.get(`/Attachment/${id}/thumb`, {
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }

  /**
   * Получить вложение
   * @param id
   */
  getAttachmentDownload(id) {
    return http.get(`/Attachment/${id}`, {
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }

  /**
   * Получить все документы из заявки
   * @param id
   */
  getDocumentsDownload(id) {
    return http.get(`/claim/${id}/documents`, {
      responseType: 'blob',
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }

  /**
   * Получить все фото из заявки архивом
   * @param claimId id заявки
   */
  getPhotosDownload(claimId) {
    return http.get(`/Claim/${claimId}/photos`, {
      responseType: 'blob',
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }
  /**
   * Копировать фото во вложения к заявке
   * @param id id фото
   * @param claimId id заявки
   */
  copyToClaim(id, claimId) {
    return http.get(`/PrePhoto/${id}/copytoclaim/${claimId}`, {
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }
  /**
   * Удалить файл
   * @param id
   */
  attachmentDelete(id) {
    return http.delete(`/Attachment/${id}`, {
      headers: {
        'Authorization': this._basicAuth
      }
    }).catch((error) => {
      this.errorCatch(error);
      return Promise.reject(error)
    }
    );
  }

  /**
   * Добавить файл
   * @param data{{File: File, ClaimId: string|number, SectionType: string|number, AttachmentType: string|number, Filename: string|number, ClaimId: string|number}}
   */
  createAttachment(data) {
    let bodyFormData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      bodyFormData.append(key, value);
    }
    return http({
      method: "post",
      url: `/Attachment`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': this._basicAuth
      },
    })
      .catch((error) => {
        console.log(error);
        this.errorCatch(error);
        return Promise.reject(error)
      }
      );
  }
}
