<template>
  <div>
    <v-snackbar
      color="deep-purple accent-4"
      :top="true"
      :right="true"
      v-model="snackbar"
      :timeout="2000"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Закрыть </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogDelete" persistent max-width="900">
      <v-card>
        <v-card-title class="text-h5">{{ textAlert }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="close">Нет</v-btn>
          <v-btn color="success" depressed @click="confirm">Да</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-form-widget
      v-model="dialogController"
      :model-value="modelData"
      @save="save"
    >
      <template v-slot:form="{ item }">
        <v-btn
          style="margin-left: 10px"
          small
          depressed
          color="blue"
          @click="saveAndSpend(item)"
          >Сохранить и провести
        </v-btn>
        <v-btn
          v-if="lastAddedItem || saving"
          :loading="saving"
          small
          depressed
          color="warning"
          @click="openFullForm(item)"
          >Полная форма
        </v-btn>
      </template>
    </app-form-widget>
    <template v-if="progress">
      <app-list-table
        ref="listTable"
        :props-model="propsModel"
        :props-data-request="propsDataRequest"
        :props-filter="propsFilter"
        :props-filter-table="FilterTable"
        :props-mutation-data="[mutationCheckListsDescription]"
        v-on:edited-item-event="editedItemEvent"
      >
        <template v-slot:header>
          <v-btn
            style="margin-left: 10px"
            color="warning"
            dark
            class="mb-2"
            @click="showModelSimilarDocCheck"
          >
            Добавить по образцу
          </v-btn>
        </template>
        <template v-slot:form="{ item, edit }">
          <v-progress-circular
            v-if="load"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <template v-else>
            <template v-if="editedItem">
              <v-btn
                style="margin-left: 10px; color: white"
                small
                depressed
                color="indigo"
                @click="commitDoc(item)"
              >
                Записи документа
              </v-btn>
              <v-btn
                style="margin-left: 10px"
                small
                v-if="editedItem.headerId == null"
                depressed
                color="primary"
                @click="commit(editedItem)"
                >Не проведено
              </v-btn>
              <v-btn
                style="margin-left: 10px"
                small
                v-else
                depressed
                color="success"
                @click="rollback(editedItem)"
                >Проведено
              </v-btn>
            </template>
          </template>
          <v-btn
            style="margin-left: 10px"
            small
            depressed
            color="blue"
            @click="saveAndSpend(item, edit)"
            >Сохранить и провести</v-btn
          >
        </template>
        <template v-slot:btn="{ item }">
          <btn-download :item="item" :event="downloadFile"></btn-download>
          <v-progress-circular
            v-if="load"
            :size="15"
            :width="1"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <template v-else>
            <v-btn
              style="margin-left: 10px"
              small
              v-if="item.headerId == null"
              depressed
              color="primary"
              @click="commit(item)"
              >Не проведено
            </v-btn>
            <v-btn
              style="margin-left: 10px"
              small
              v-else
              depressed
              color="success"
              @click="rollback(item)"
              >Проведено
            </v-btn>
          </template>
        </template>
      </app-list-table>
    </template>
    <v-overlay v-else>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { apiDirectory, TypeFilter } from "../../../api/api-directory";
import AppListTable from "../../widget/AppListTable";
import { apiBlank } from "../../../api/api-blank";
import Swal from "sweetalert2";
import AppFormWidget from "@/views/widget/AppFormWidget";
import BtnDownload from "@/views/btn/btnDownload";

export default {
  name: "ListTableCheckList",
  components: {
    BtnDownload,
    AppFormWidget,
    AppListTable,
  },
  props: {
    propsModel: String,
    propsDataRequest: String,
    propsFilter: Object,
  },
  data: () => ({
    snackbarText: "",
    snackbar: false,
    FilterTable: [
      {
        name: "Все",
        id: -1,
        type: TypeFilter.CHECK,
        default: true,
        filter: [],
      },
      {
        name: "Не проведенные",
        type: TypeFilter.CHECK,
        filter: [{ field: "HeaderId", operator: 17, value: null }],
      },
      {
        name: "Проведённые",
        type: TypeFilter.CHECK,
        filter: [{ field: "HeaderId", operator: 2, value: 0 }],
      },
      { name: "Фильтр по дате", type: TypeFilter.DATE_TIME },
    ],
    modelData: null,
    dialogController: null,
    progress: false,
    model: "",
    textAlert: "",
    dialogDelete: false,
    apiBlank: "",
    type: null,
    item: null,
    load: false,
    saving: false,
    editedItem: null,
    lastAddedItem: null,
    docCheckLists: null,
  }),
  watch: {
    'dialogController.dialog'() {
        this.lastAddedItem = null
    }
  },
  created() {
    this.apiBlank = new apiBlank(this.propsModel);
    this.apiBlank.docCheckListsDescription().then((value) => {
      this.docCheckLists = value.data.data;
      this.progress = true;
    });
    this.getModelData();
  },
  methods: {
    async downloadFile(item) {
      return new Promise((resolve, reject) => {
        this.apiBlank.GetDownloadDocument(item).then((request) => {
          resolve(0);
          if (request != null) {
            // let blob = new Blob([request.data], { type: request.headers['content-type'] }),
            let url = window.URL.createObjectURL(request.data);
            let a = document.createElement("a");
            let contentDisposition = require("content-disposition");
            if (request.headers["content-disposition"]) {
              var disposition = contentDisposition.parse(
                request.headers["content-disposition"]
              );
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = disposition.parameters.filename;
              a.click();
            } else {
              window.open(url, "_blank");
            }
          }
        }).catch(() => {
          reject()
        })
      });
    },
    async save(v) {
      this.saving = true

      try {
        delete v["Id"];
        await this.apiBlank.SimilarDocCheckListModel(v);
        // this.dialogController.dialog = false;
        this.snackbarText = "Запись успешно сохранена";
        this.snackbar = true;
        await this.$refs.listTable.initialize(
          this.propsModel,
          this.propsDataRequest
        );
        this.lastAddedItem = await this.$refs.listTable.getItemByClaimId(v.ClaimId);
      } catch (e) {
        console.log(e);
      }

      this.saving = false
    },
    openFullForm() {
      this.dialogController.dialog = false;
      this.$refs.listTable.editItem(this.lastAddedItem);
      this.lastAddedItem = null;
    },
    showModelSimilarDocCheck() {
      this.dialogController.dialog = true;
    },
    async getModelData() {
      let request = await this.apiBlank.SimilarDocCheckListModelModel();
      this.modelData = request.data.properties;
    },
    valueName(value) {
      return value.charAt(0).toLowerCase() + value.slice(1);
    },
    parseStringToArray(value) {
      let options = [];
      let arrayValue = value.split("|");
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(arrayValue)) {
        options.push({
          text: value,
          value: value,
        });
      }
      return options;
    },
    mutationCheckListsDescription(listForm) {
      if (this.docCheckLists == null) {
        Swal.fire(
          "Ошибка интерфейса",
          "Данные не успели загрузиться docCheckListsDescription откройте редактирования занова",
          "error"
        );
      }
      this.docCheckLists.forEach((docCheckItem) => {
        let findItemIndex = listForm
          .map(function (e) {
            return e.key;
          })
          .indexOf(docCheckItem.columnName);
        if (findItemIndex !== -1) {
          if (docCheckItem.type === 1) {
            listForm[findItemIndex].type = "enum";
            listForm[findItemIndex].options = this.parseStringToArray(
              docCheckItem.values
            );
          }
          if (docCheckItem.groupName != null)
            listForm[findItemIndex].groupLine = docCheckItem.groupName;
          if (docCheckItem.name != null)
            listForm[findItemIndex].groupGrid = docCheckItem.name;
          if (docCheckItem.postfix != null)
            listForm[findItemIndex].postfix = docCheckItem.postfix;
          if (docCheckItem.prefix != null)
            listForm[findItemIndex].prefix = docCheckItem.prefix;
          if (docCheckItem.sort != null)
            listForm[findItemIndex].sort = docCheckItem.sort;
          if (docCheckItem.enabled != null)
            listForm[findItemIndex].disabled = docCheckItem.enabled === 0;
          listForm[findItemIndex].text = "";
        }
      });
      listForm.forEach((list) => {
        !list.sort && (list.sort = 0);
      });
      listForm.sort(function (a, b) {
        return a.sort - b.sort;
      });
      return listForm;
    },
    editedItemEvent(item) {
      this.editedItem = item;
    },
    commit(item) {
      this.dialogDelete = true;
      this.textAlert = "Вы уверены, что хотите провести бланк?";
      this.type = "commit";
      this.item = item;
    },
    async saveAndSpend(item, edit = -1) {
      this.item = item;
      if (edit === -1) this.item["Id"] = 0;
      try {
        await this.apiBlank.CreateCommitCheckList(this.item);
        this.dialogController.dialog = false;
        this.close();
        this.snackbarText = "Запись успешно сохранена";
        this.snackbar = true;
      } catch (e) {}
    },
    rollback(item) {
      this.dialogDelete = true;
      this.textAlert = "Вы уверены, что хотите откатить бланк?";
      this.type = "rollback";
      this.item = item;
    },
    close() {
      this.dialogDelete = false;
    },
    confirm() {
      this.dialogDelete = false;
      this.sendServer();
    },
    async sendServer() {
      let request;
      try {
        this.load = true;
        switch (this.type) {
          case "commit":
            request = await this.apiBlank.commit(this.item.id);
            break;
          case "rollback":
            request = await this.apiBlank.rollback(this.item.id);
            break;
          default:
            console.log("Не выброн тип операции");
            //Здесь находятся инструкции, которые выполняются при отсутствии соответствующего значения
            //statements_def
            break;
        }
        await this.$refs.listTable.initialize(
          this.propsModel,
          this.propsDataRequest
        );
        await this.$refs.listTable.close();
        this.$forceUpdate();
      } catch (e) {
        if (typeof e.response.data === "object") {
          Swal.fire(e.response.data.title, e.response.data.detail, "error");
        } else {
          Swal.fire("Не обработанная ошибка", e.response.data, "error");
        }
      }
      this.load = false;
      return request;
    },
    async sendServerDoc(item) {
      let request = await this.apiDocument.document(item.id);
      console.log(request);
      //
      // let blob = new Blob([request.data], { type: request.headers['content-type'] }),
      let url = window.URL.createObjectURL(request.data);
      window.open(url, "_blank");
      this.load = false;
    },
  },
};
</script>

<style scoped></style>
