<template>
  <div>
    <app-confirm-dialog ref="confirmDialog"></app-confirm-dialog>
    <v-snackbar
        color="deep-purple accent-4"
        :top="true"
        :right="true"
        v-model="snackbar"
        :timeout="2000"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="loader">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-radio-group v-if="propsFilterTable != null ? propsFilterTable.length > 0 : false" row v-model="filterTableValue"
                   @change="onChangeFilterTable" class="radio-group-filter">
      <template v-for="n in propsFilterTable">
        <v-radio
            v-if="isCheckFilter(n)"
            :key="n.name"
            :label="n.name"
            :value="n.filter"
        ></v-radio>
      </template>
      <v-menu
          v-if="isDateTimeFilter(propsFilterTable)"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="dateRangeText"
              label="Выберите дату"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="dates"
            no-title
            range
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="clearDate"> Сброс </v-btn>
          <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
          <v-btn
              text
              color="primary"
              @click="clearDate"
          >
            Сброс
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="menu = false"
          >
            Отмена
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="() => {
                                filterDateRangeText();
                                return $refs.menu.save(dateRangeText)
                            }"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-radio-group>
    <v-data-table
        v-model="selected"
        show-select
        fixed-header
        height="75vh"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
        :items-per-page="30"
        :server-items-length="tableServerPaginate === true ? totalDesserts : -1"
        :item-class="rowClass"
        :headers="headers.filter(header => headerFilter(header))"
        :items="desserts"
        class="elevation-1"
    >
      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ header, value }">
        <app-table-item v-bind:key="header.value" :props-header="header"
                        :text="value"></app-table-item>
      </template>
      <template slot="headers" slot-scope="props">
        <tr>
          <th>
            <v-checkbox
                :input-value="props.all"
                :indeterminate="props.indeterminate"
                primary
                hide-details
                @click.native="toggleAll"
            ></v-checkbox>
          </th>
          <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
          >
            <v-icon small>arrow_upward</v-icon>
            {{ header.text }}
          </th>
        </tr>
        <tr class="grey lighten-3">
          <th>
            <v-icon>filter_list</v-icon>
          </th>
          <th
              v-for="header in props.headers"
              :key="header.text"
          >
            <div v-if="filters.hasOwnProperty(header.value)">
              <v-select flat hide-details small multiple clearable :items="columnValueList(header.value)"
                        v-model="filters[header.value]">

              </v-select>

            </div>
          </th>
        </tr>
      </template>
      <template v-slot:footer.prepend>
        <v-row style="margin: 0">
          <v-col>
            <v-select
                :items="optionsActions"
                v-model="optionsActionsValue"
                item-text="name"
                item-value="id"
                label="Действия"
            ></v-select>
          </v-col>
          <v-col>
            <v-btn
                class="mt-3"
                color="success"
                @click="eventAction"
            >Применить
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-col align="left">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="showDialog()"
            >
              Добавить
            </v-btn>
            <slot name="header"></slot>
          </v-col>
          <app-form-widget
              :edit="editedIndex > -1"
              v-model="dialogController"
              ref="dialogEdit"
              :model-value="modelData"
              :props-mutation-data="propsMutationData"
              @save="save"
          >
            <template v-slot:form="{ item }">
              <slot name="form" v-bind:item="item" v-bind:edit="editedIndex"></slot>
            </template>
          </app-form-widget>
          <v-toolbar-title>{{ name }}</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-xs-right">
          <v-btn
              v-if="propsSelectMode"
              class="mr-2"
              color="success"
              x-small
              small
              @click="selectItem(item)"
          >
            Выбрать
          </v-btn>
          <v-btn
              @click="editItem(item)"
              color="primary"
              fab
              x-small
              dark
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
              style="margin-left: 10px"
              @click="deleteItem(item)"
              color="warning"
              fab
              x-small
              dark
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <slot name="btn" v-bind:item="item"></slot>
        </div>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="() => initialize(model, propsDataRequest)"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {apiDirectory, TypeFilter} from "../../api/api-directory";
import {format} from 'date-fns'
import AppTableItem from "./AppTableItem";
import Swal from "sweetalert2";
import config from '@/config';
import AppConfirmDialog from "./AppConfirmDialog";
import AppFormWidget from "./AppFormWidget";
import {Agh} from "@/helper/app";

export default {
  name: "AppListTable",
  props: {
    propsModel: String,
    propsDataRequest: String,
    propsFilter: Object,
    propsFilterTable: Array,
    propsSelectMode: {
      type: Boolean,
      default: false
    },
    propsMutationData: {
      type: Array,
    },
  },
  components: {
    AppFormWidget,
    AppConfirmDialog,
    AppTableItem,
  },
  data: () => ({
    dialogController: null,
    dates: [],
    menu: false,
    filterAll: [],
    filterTableValue: 1,
    filterTableValueDateTime: [],
    optionsActions: [{id: 1, name: 'Удалить'}, {id: 2, name: 'Копировать'}],
    optionsActionsValue: 1,
    snackbarText: '',
    selected: [],
    snackbar: false,
    totalDesserts: 200,
    loading: false,
    options: {},
    tableServerPaginate: false,
    loader: false,
    search: '',
    apiDirectory: null,
    name: '',
    model: '',
    modelData: null,
    headers: [],
    desserts: [],
    sheet: false,
    editedIndex: -1,
    editedItem: {},
    attributeFilterObject: {},
    timer: null,
    Int32: v => {
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 2147483647) return true;
      return 'Число должно быть от 0 до 2 147 483 647.';
    },
    Decimal: v => {
      if (!isNaN(parseFloat(v))) return true;
      return 'Число должно быть от Decimal';
    },
    Double: v => {
      if (!isNaN(parseFloat(v))) return true;
      return 'Число должно быть от Double';
    },
  }),
  computed: {
    dateRangeText() {
      if (this.dates.length >= 2) {
        let one = new Date(this.dates[0]);
        let to = new Date(this.dates[1]);
        return `${format(one.getTime() < to.getTime() ? one : to, "dd-MM-yyyy")} ~ ${format(one.getTime() < to.getTime() ? to : one, "dd-MM-yyyy")}`;
      } else
        return "";
    },
  },
  watch: {
    options: {
      handler(v) {
        if (this.tableServerPaginate) {
          this.desserts = [];
          this.getData(this.options.page, this.options.itemsPerPage, this.orderBy(v)).then(data => {
            this.desserts = data.data
          })
        }
        // this.getDataFromApi()
      },
      deep: true,
    },
    search() {
      clearTimeout(this.timer);
      let ms = config.searchTimeout;
      this.timer = setTimeout(() => {
        this.onChangeFilterTable()
      }, ms);
    },
    'propsModel': {
      handler(val) {
        this.name = 'Загрузка...'
        this.headers = [];
        this.desserts = [];
        this.search = '';
        this.model = val;
        this.initialize(val, this.propsDataRequest);
      },
      deep: true
    },
    'propsFilterTable': {
      handler(val) {
        this.selectCheckFilter(val);
      }
    }
  },
  created() {
    this.model = this.propsModel;
    this.filterAll = this.propsFilter;
    if (this.propsFilterTable != null)
      this.propsFilterTable.forEach((value, index) => {
        if (value.type === TypeFilter.CHECK)
          if (value.default)
            if (value.default === true) {
              this.filterTableValue = this.propsFilterTable[index].filter;
            }

      });
    this.initialize(this.model, this.propsDataRequest);
  },
  methods: {
    orderBy(v) {
      if (this.tableServerPaginate) {
        if (v.sortBy.length > 0) {
          let name = Agh.firstUpperCase(v.sortBy[0]);
          return {
            order: { // сортировка
              [name]: v.sortDesc[0] === true ? 1 : 0, // 0 - asc, 1 - desc
            }
          }
        }
      }
      return {}
    },
    showDialog() {
      this.dialogController.dialog = true;
      this.dialogController.dataValue = null;
      this.editedIndex = -1;
    },
    addOptionsActions(id, name) {
      this.optionsActions.push({id: id, name: name});
    },
    filterDateRangeText() {
      if (this.dates.length > 0) {
        let one = new Date(this.dates[0]);
        let to = new Date(this.dates[1]);
        this.filterTableValueDateTime = [];
        this.filterTableValueDateTime.push({
          field: 'Created',
          operator: 16,
          value: format(one.getTime() < to.getTime() ? one : to, "yyyy-MM-dd'T'00:00:00"),
          value2: format(one.getTime() < to.getTime() ? to : one, "yyyy-MM-dd'T'23:59:59")
        })
        this.onChangeFilterTable();
      }
    },
    selectCheckFilter(val) {
      if (val != null)
        val.forEach((value, index) => {
          if (value.type === TypeFilter.CHECK)
            if (value.default)
              if (value.default === true) {
                this.filterTableValue = this.propsFilterTable[index].filter;
                this.onChangeFilterTable();
              }

        });
    },
    clearDate() {
      this.menu = false
      this.dates = [];
      this.filterTableValueDateTime = [];
      this.onChangeFilterTable()
    },
    isCheckFilter(n) {
      return n.type === TypeFilter.CHECK;
    },
    isDateTimeFilter(n) {
      let boolFilter = false;
      if (n == null)
        return false;
      if (n.length > 0) {
        n.forEach(v => {
          if (v.type === TypeFilter.DATE_TIME)
            boolFilter = true;
        });
      }
      return boolFilter;
    },
    rowClass() {
      const rowClass = 'item-tr-class'
      return rowClass;
    },
    async onChangeFilterTable() {
      this.filterAll = this.propsFilter;
      try {
        if (this.filterAll.filter == null) {
          this.filterAll = {
            filter: []
          }
        }
      } catch (e) {
        this.filterAll = {
          filter: []
        }
      }
      if (this.tableServerPaginate === true) {
        console.log(this.search);
        this.filterAll.filter.push({
          "field": "Id",
          "operator": 15,
          "value": this.search
        });
      }
      if (this.filterTableValue.length > 0) {
        this.filterTableValue.forEach(value => {
          this.filterAll.filter.push(value);
        });
      }
      if (this.filterTableValueDateTime.length > 0) {
        this.filterTableValueDateTime.forEach(value => {
          this.filterAll.filter.push(value);
        })
      }
      await this.initialize(this.model, this.propsDataRequest);
    },
    async eventAction() {
      if (this.optionsActionsValue === 1) {
        try {
          this.$refs.confirmDialog.show();
          this.$refs.confirmDialog.addEventListenerOnce(async (confirm) => {
            if (confirm) {
              for await (const selected of this.selected) {
                await this.apiDirectory.delete(selected.id);
              }
              this.snackbarText = 'запись успешно удалена';
              this.snackbar = true;
              this.selected = [];
              await this.initialize(this.model, this.propsDataRequest);
            }
          });
        } catch (e) {
          console.log(e);
        }
      }
      if (this.optionsActionsValue === 2) {
        try {
          if (this.selected != null) {
            if (this.selected.length > 0) {
              if (this.selected.length > 1) {
                this.snackbarText = 'Копирования доступно только для одного элемента';
                this.snackbar = true;
              } else {
                this.dialogController.dataValue = this.selected[0];
                this.$emit('edited-item-event', this.selected[0]);
                this.dialogController.dialog = true;
                console.log(this.dialogController);
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
      this.$emit('all-item-event', {action: this.optionsActionsValue, select: this.selected})
    },
    async getData(page = 1, rows = 1000, order = {}) {
      this.loading = true
      let value = await this.apiDirectory.getAll(page, rows, order, this.filterAll);
      this.loading = false
      return value.data;
    },
    async initialize(modelInit, dataRequest) {
      this.loader = true;
      this.apiDirectory = new apiDirectory(modelInit, dataRequest);
      let model;
      this.headers = [];
      this.editedItem = [];
      this.headers.push({text: '', value: 'actions', sortable: false});
      try {
        model = await this.apiDirectory.getModel();
        this.modelData = model.data.properties;
        this.apiDirectory.setModel(this.modelData);
      } catch (e) {
        console.warn(e);
      }
      if (model) {
        this.tableServerPaginate = model.data.quantity > 1000;
        this.name = model.data.name;
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(model.data.properties)) {
          let options = [];
          // console.log(`${key}: ${value}`);
          let valueName = this.valueName(value.entityName);
          let disabled = false;
          let isEnum = false;
          let type = 'text';
          let dataValue = '';
          let textType = [];
          let hiddenTable = false;
          let hiddenForm = false;
          if (value.attributes.ReadOnlyAttribute == "True" || value.attributes.EditableAttribute == "False")
            disabled = true;
          if (value.isEnum) {
            isEnum = true;
            dataValue = value.attributes.Values
            type = 'enum';
            for (const [key, value] of Object.entries(this.parseStringToArray(dataValue))) {
              options.push({
                text: value,
                value: parseInt(key),
              });
            }
          }
          if (valueName == "id" || valueName == "createdBy" || valueName == "created")
            disabled = true;

          if (value.attributes.MultilineAttribute == "True")
            type = 'textarea';
          let modelTable = '';
          let modelName = '';
          if (value.attributes.DataSourceAttribute != null) {
            let DataSourceArray = value.attributes.DataSourceAttribute.split('|');
            modelTable = DataSourceArray[1].slice(4);
            modelName = this.valueName(DataSourceArray[2]);
            type = 'select';
          }
          if (value.entityType === 'DateTime') {
            type = 'dateTime';
          }
          if (value.entityType === 'Int32') {
            textType = [this.Int32];
          }
          if (value.entityType === 'Decimal') {
            textType = [this.Decimal];
          }
          if (value.entityType === 'Double') {
            textType = [this.Double];
          }
          if (value.attributes.HiddenAttribute) {
            let HiddenAttribute = value.attributes.HiddenAttribute.split('|')
            hiddenTable = HiddenAttribute[0].toLowerCase() === 'true';
            hiddenForm = HiddenAttribute[1].toLowerCase() === 'true';
          }
          this.headers.push({
            text: value.name,
            value: valueName,
            key: valueName,
            disabled: disabled,
            type: type,
            textType: textType,
            options: options,
            modelTable: modelTable,
            modelName: modelName,
            dataValue: dataValue,
            isEnum: isEnum,
            hiddenTable: hiddenTable,
            hiddenForm: hiddenForm,
          })
        }
        if (this.tableServerPaginate) {
          this.totalDesserts = model.data.quantity;
          let dataRow = await this.getData(1, this.options.itemsPerPage, this.orderBy(this.options));
          if (dataRow.pages === 1)
            this.totalDesserts = dataRow.data.length;
          this.desserts = dataRow.data;
        } else {
          let dataRow = await this.getData(1, 1000, this.orderBy(this.options));
          this.desserts = dataRow.data;
        }
      }
      this.loader = false;
    },
    showTable() {
      this.sheet = true;
    },
    parseStringToArray(value) {
      let newObject = {};
      let arrayValue = value.split(';')
      arrayValue.forEach(item => {
        let objectItem = item.split('=')
        newObject[objectItem[0]] = objectItem[1];
      })
      return newObject;
    },
    selectEvent(value, header) {
      console.log(value);
      header.sheet = false;
      if (header.options.filter(value => value.id === value.id) > 0) {
        header.value = {
          key: value.id,
          value: value.name
        };
      } else {
        header.value = {
          key: value.id,
          value: value.name
        };
      }
    },
    async editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);

      this.dialogController.dataValue = item;
      this.$emit('edited-item-event', item)
      this.dialogController.dialog = true
    },
    async getItemByClaimId(claimId) {
      let filter = {
        filter: [
          { field: "ClaimId", operator: 0, value: claimId },
        ]
      }
      let response = await this.apiDirectory.getAll(1, 1, null, filter);
      let item = response.data?.data[0]
      if(item) {
        item = Object.fromEntries(Object.entries(item).filter(([key, value]) => value))
        console.log(claimId, item)
        return item
      }
      return null
    },
    selectItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = this.getModelAddEdit(item);
      this.$emit('select', item);
    },
    deleteItem(item) {
      let index = this.desserts.indexOf(item)
      this.$refs.confirmDialog.show();
      this.$refs.confirmDialog.addEventListenerOnce(async (confirm) => {
        if (confirm) {
          await this.apiDirectory.delete(this.desserts[index].id);
          this.snackbarText = 'запись успешно удалена';
          this.snackbar = true;
          await this.initialize(this.model, this.propsDataRequest);
        }
      });
    },
    headerFilter(header) {
      if (header.text.search('#') !== -1)
        return false;
      if (header.hiddenTable === true)
        return false;

      return true;
    },
    close() {
      this.dialogController.dialog = false
      this.$nextTick(() => {
        this.editedIndex = -1;
      })
    },
    save(value) {
      console.log(value);
      if (this.editedIndex > -1) {
        this.updateServer(value);
        Object.assign(this.desserts[this.editedIndex], value);
      } else {
        this.cratedServer(value);
      }
    },
    async cratedServer(edited) {
      try {
        delete edited['Id'];
        await this.apiDirectory.create(edited);
        this.snackbarText = 'Запись успешно сохранена';
        this.snackbar = true;
        this.close();
        await this.initialize(this.model, this.propsDataRequest);
      } catch (e) {
        console.log(e);
      }
    },
    async updateServer(edited) {
      try {
        await this.apiDirectory.update(edited);
        this.snackbarText = 'Запись успешно обновлена';
        this.snackbar = true;
        this.close();
        await this.initialize(this.model, this.propsDataRequest);
      } catch (e) {
        console.log(e);
      }
    },
    valueName(value) {
      return value.charAt(0).toLowerCase() + value.slice(1);
    },
  },
}
</script>

<style lang="scss">
.v-data-table-header span {
  width: 100%;
  display: block;
}
.text-xs-right {
  white-space: nowrap;
}
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
  /*white-space: break-spaces;*/
}
.radio-group-filter {
  .v-input--radio-group__input {
    padding-left: 24px;
    /*justify-content: flex-end;*/
  }
}
</style>
