<template>
  <div>
    <template v-if="propsHeader.key === 'statusName'">
      <v-chip
          class="ma-2"
          :style="'background-color: ' + getStatusColor(text)"
      >
        <span>{{ getText(text) }}</span>
      </v-chip>
<!--      <span class="show" v-on:click="showAndHideText"></span>-->
    </template>
    <template v-else>
      {{ getText(text) }}
    </template>
  </div>
</template>

<script>
  const type = {
    isDate: 0,
    isEnum: 1,
    FORGOT_PASSWORD: 2,
  };

  // eslint-disable-next-line no-unused-vars
  import {parseISO, format} from "date-fns";

  export default {
    name: "AppTableItem",
    data: () => ({
      show: true,
      maxText: false,
      slice: 30,
      type: null,
      data: null,
      listEnum: []
    }),
    props: {
      propsHeader: {
        type: Object,
        default: function () {
          return {
            isEnum: false,
            entityType: 'text',
            dataValue: '',
            key: 'null'
          }
        }
      },
      text: [String, Number],
    },
    created() {
      if (this.propsHeader.isEnum) {
        this.type = type.isEnum;
        this.listEnum = this.parseStringToArray(this.propsHeader.dataValue);
      } else if (typeof this.text === 'string') {
        this.maxText = this.text.length > this.slice;
        // let date = parseISO(this.text);
        // if (isValid(date)) {
        if (this.propsHeader.type === 'dateTime')
          this.type = type.isDate;
        // }
      }
    },
    methods: {
      getStatusColor(text) {
        if (text === 'Введен')
          return 'var(--v-success-lighten4)';
        if (text === 'В обработке')
          return 'var(--v-error-lighten4)';
        return 'inherit';
      },
      parseStringToArray(value) {
        let newObject = {};
        let arrayValue = value.split(';')
        arrayValue.forEach(item => {
          let objectItem = item.split('=')
          newObject[objectItem[0]] = objectItem[1];
        })
        return newObject;
      },
      showAndHideText() {
        this.show = !this.show;
      },
      getText(text) {
        switch (this.type) {
          case type.isDate: {
            return format(parseISO(text), "dd.MM.yyyy HH:mm")
          }
          case type.isEnum:
            return this.listEnum[text];
          default:
            return text;
        }
        // if (this.type === type.isDate) {
        //    return format(parseISO(text), "dd.MM.yyyy HH:mm:ss")
        // }
        // if (this.show) {
        //    return text;
        // } else {
        //     return text.slice( 0, this.slice) + '...';
        // }
      }
    }
  }
</script>

<style scoped>
  .show {
    color: blue;
    cursor: pointer;
  }
</style>