<template>
    <v-dialog v-model="dialog" persistent
              max-width="900">
        <v-card>
            <v-card-title class="text-h5">Вы уверены, <br/> что хотите удалить этот элемент?</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" depressed @click="close">Нет</v-btn>
                <v-btn color="success" depressed @click="confirm">Да</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AppConfirmDialog",
        data: () => ({
            dialog: false,
            // eslint-disable-next-line no-unused-vars
            event: async function (confirm) {
            }
        }),
        methods: {
            show() {
                this.dialog = true;
            },
            close() {
                this.dialog = false;
                this.event(false);
            },
            confirm() {
                this.dialog = false;
                this.event(true);
                // eslint-disable-next-line no-unused-vars
                this.event = async function (confirm) {
                }
            },
            addEventListenerOnce(event) {
                this.event = event;
            }
        }
    }
</script>

<style scoped>

</style>