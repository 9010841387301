<template>
    <div>
        <v-dialog
                v-model="dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Уведомления
                </v-card-title>
                <v-card-text>{{ dialogText }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="upload.dialog"  persistent
                  max-width="900">
            <v-card>
                <v-card-title class="text-h5">Загрузить файла</v-card-title>
                <v-card-actions>
                    <v-btn color="primary" depressed @click="closeDialog">Отмена</v-btn>
                    <v-btn color="success" depressed @click="confirmDialog">Загрузить</v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-container>
                        <v-autocomplete
                                v-model="upload.type"
                                :items="typeItemsUpload"
                                item-text="name"
                                item-value="id"
                                label="Тип файла"
                                outlined
                                return-object
                        ></v-autocomplete>
                        <v-file-input
                                v-model="upload.file"
                                label="Файл"
                                outlined
                        ></v-file-input>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <app-list-table
                ref="listTable"
                :props-model="propsModel"
                :props-data-request="propsDataRequest"
                :props-filter="propsFilter"
                v-on:edited-item-event="editedItemEvent"
        >
            <template v-slot:form>
                <v-progress-circular
                        v-if="load"
                        :size="15"
                        :width="1"
                        indeterminate
                        color="primary"
                ></v-progress-circular>
                <template v-else>
                    <v-btn
                            style="margin-left: 10px"
                            small
                            depressed
                            color="indigo"
                            @click="() => {commit(editedItem, 1)}"
                    >
                        <v-icon color="white">mdi-file-download-outline</v-icon>
                    </v-btn>
                </template>
            </template>
            <template v-slot:btn="{ item }">
                <v-progress-circular
                        v-if="load"
                        :size="15"
                        :width="1"
                        indeterminate
                        color="primary"
                ></v-progress-circular>
                <template v-else>
                    <v-btn
                            style="margin-left: 10px"
                            x-small
                            fab
                            color="indigo"
                            @click="() => {commit(item, 1)}"
                    >
                        <v-icon color="white">mdi-file-download-outline</v-icon>
                    </v-btn>
                </template>
            </template>
            <template v-slot:header>
                <v-btn
                        style="margin-left: 10px"
                        color="warning"
                        dark
                        class="mb-2"
                        v-on:click="showDialog"
                >
                    Загрузить
                </v-btn>
            </template>
        </app-list-table>
    </div>
</template>

<script>
    import AppListTable from "../../widget/AppListTable";
    import {apiDocument} from "../../../api/api-document";
    import Swal from 'sweetalert2'
    import http from "../../../api/http-common";
    import store from "../../../store";
    import {apiDirectory} from "../../../api/api-directory";

    export default {
        name: "ListTableFile",
        components: {
            AppListTable,
        },
        props: {
            propsModel: String,
            propsDataRequest: String,
            propsFilter: Object,
        },
        data: () => ({
            model: '',
            textAlert: '',
            dialog: false,
            dialogText: '',
            apiDocument: '',
            typeFile: -1,
            item: null,
            load: false,
            editedItem: null,
            typeItemsDownload: [
                { name: 'Скачать все (Ошибка это стандартные поля)', id: -1 },
            ],
            typeItemsUpload: [
                { name: 'Скачать все (Ошибка это стандартные поля)', id: -1 },
            ],
            upload: {
                dialog: false,
                type: 0,
                file: null,
            },
        }),
        created() {
            this.apiDocument = new apiDocument();
            this.apiDirectory = new apiDirectory(this.propsModel, this.propsDataRequest);
            this.init();
        },
        methods: {
            async init() {
                let model = await this.apiDirectory.getFileType(this.propsDataRequest);
                if ((typeof model.data.upload !== "object") && (typeof model.data.download !== "object"))
                    return Swal.fire(
                        "Ошибка",
                        `В документе нет объектов со значением
                         ${(typeof model.data.download !== "object") ? 'Отсутствует файл загрузки (download)' : ''}
                         ${(typeof model.data.upload !== "object") ? 'Отсутствует файл отправки (upload)' : ''}`,
                        'error'
                    );
                else {
                    this.typeItemsDownload = [
                        { name: 'Скачать все', id: -1 },
                    ];
                    this.typeItemsUpload = [ ];
                    for (const [key, value] of Object.entries(model.data.download)) {
                        this.typeItemsDownload.push({
                            name: value,
                            id: key
                        });
                    }
                    for (const [key, value] of Object.entries(model.data.upload)) {
                        this.typeItemsUpload.push({
                            name: value,
                            id: key
                        });
                    }
                }
            },
            parseStringToArray(value) {
                let newObject = {};
                let arrayValue =  value.split(';')
                arrayValue.forEach(item => {
                    let objectItem = item.split('=')
                    newObject[objectItem[0]] = objectItem[1];
                })
                return newObject;
            },
            showDialog() {
                this.upload.dialog = true;
                this.upload.type = null;
                this.upload.file = null;
            },
            closeDialog() {
                this.upload.dialog = false;
            },
            confirmDialog() {
                this.closeDialog();
                this.submitFiles(this.upload.file, this.upload.type.id)
            },
            editedItemEvent(item) {
                console.log(this.editedItem);
                this.editedItem = item;
            },
            commit(item, type) {
                this.load = true;
                // this.apiDocument.document(item.id)
                this.sendServer(item, type);
            },
            IsJsonString(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            async sendServer(item) {
                let request = null;
                try {
                    // if (this.typeFile === -1)
                        request = await this.apiDocument.UploadFile(item.id);
                    // else
                    //     request = await this.apiDocument.documentType(item.id, item.fileType);
                } catch (e) {
                    let json = await e.response.data.text();
                    if (this.IsJsonString(json)) {
                        Swal.fire(
                            JSON.parse(json).title,
                            JSON.parse(json).detail,
                            'error'
                        )
                    } else {
                        Swal.fire(
                            'Не обработанная ошибка',
                            json,
                            'error'
                        )
                    }
                }
                if (request != null) {
                    var link = document.createElement("a");
                    document.body.appendChild(link);
                    link.setAttribute("type", "hidden");
                    link.href = "data:" + request.data.fileContents + ";base64," + request.data.fileContents;
                    link.download = request.data.fileDownloadName;
                    link.click();
                    document.body.removeChild(link);
                    // // console.log(request);
                    // // console.log({ type: request.data.contentType, dd: request.data.fileContents });
                    // console.log(window.atob(request.data.fileContents));
                    // let blob = new Blob([window.atob(request.data.fileContents)], { type: request.data.contentType });
                    // let url = window.URL.createObjectURL(blob)
                    // window.open(url, "_blank")
                }
                this.load = false;
            },
            submitFiles(file, id) {
                let formData = new FormData();
                formData.append("File", file, file.name);
                formData.append("FileType", id);
                http
                    .post("/UploadFile", formData, {
                        headers: {
                            'Authorization': `Bearer ${store.getters.token}`
                        }
                    })
                    .then(response => {
                        console.log("Success!");
                        this.dialog = true;
                        this.dialogText = 'Загрузка прошла успешно';
                        this.$refs.listTable.initialize(this.propsModel, this.propsDataRequest, this.propsFilter);
                        console.log({ response });
                    })
                    .catch(error => {
                        let errors = "Неизвестная ошибка";
                        if (error.response.data.errors != null)
                            Object.keys(error.response.data.errors).forEach(key => {
                                error.response.data.errors[key].forEach(item => {
                                    if (errors === "Неизвестная ошибка") errors = "";
                                    errors += `[${key}] - ` + item + "\n";
                                });
                            });
                        if (error.response.data.title != null) {
                            errors = error.response.data.title;
                        }
                        this.dialogText = errors;
                        this.dialog = true;
                        console.log({ error });
                    });
            }
        }
    }
</script>

<style scoped>

</style>