<template>
    <div>
        <v-dialog v-model="dialogDelete"  persistent
                  max-width="900">
            <v-card>
                <v-card-title class="text-h5">{{ textAlert }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" depressed @click="close">Нет</v-btn>
                    <v-btn color="success" depressed @click="confirm">Да</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <app-list-table
                ref="listTable"
                :props-model="propsModel"
                :props-data-request="propsDataRequest"
                :props-filter="propsFilter"
                v-on:edited-item-event="editedItemEvent"
        >
            <template v-slot:form>
                <v-progress-circular
                        v-if="load"
                        indeterminate
                        color="primary"
                ></v-progress-circular>
                <template v-else>
                    <template v-if="editedItem">
                        <v-btn
                                style="margin-left: 10px"
                                small
                                v-if="editedItem.headerId == null"
                                depressed
                                color="primary"
                                @click="commit(editedItem)"
                        >Не проведено</v-btn>
                        <v-btn
                                style="margin-left: 10px"
                                small
                                v-else
                                depressed
                                color="success"
                                @click="rollback(editedItem)"
                        >Проведено</v-btn>
                    </template>
                </template>
            </template>
            <template v-slot:btn="{ item }">
                <v-progress-circular
                        v-if="load"
                        :size="15"
                        :width="1"
                        indeterminate
                        color="primary"
                ></v-progress-circular>
                <template v-else>
                    <v-btn
                            style="margin-left: 10px"
                            small
                            v-if="item.headerId == null"
                            depressed
                            color="primary"
                            @click="commit(item)"
                    >Не проведено</v-btn>
                    <v-btn
                            style="margin-left: 10px"
                            small
                            v-else
                            depressed
                            color="success"
                            @click="rollback(item)"
                    >Проведено</v-btn>
                </template>
            </template>
        </app-list-table>
    </div>
</template>

<script>
    import AppListTable from "../../widget/AppListTable";
    import {apiBlank} from "../../../api/api-blank";
    import Swal from 'sweetalert2'

    export default {
        name: "ListTableBlank",
        components: {
            AppListTable,
        },
        props: {
            propsModel: String,
            propsDataRequest: String,
            propsFilter: Object,
        },
        data: () => ({
            model: '',
            textAlert: '',
            dialogDelete: false,
            apiBlank: '',
            type: null,
            item: null,
            load: false,
            editedItem: null
        }),
        created() {
            this.apiBlank = new apiBlank(this.propsModel);
        },
        methods: {
            editedItemEvent(item) {
                this.editedItem = item;
            },
            commit(item) {
                console.log(item);
                this.dialogDelete = true;
                this.textAlert = 'Вы уверены, что хотите провести бланк?';
                this.type = 'commit';
                this.item = item;
            },
            rollback(item) {
                console.log(item);
                this.dialogDelete = true;
                this.textAlert = 'Вы уверены, что хотите откатить бланк?';
                this.type = 'rollback';
                this.item = item;
            },
            close() {
                this.dialogDelete = false
            },
            confirm() {
                this.dialogDelete = false
                this.sendServer();
            },
            async sendServer() {
                let request;
                this.load = true;
                try {
                    switch (this.type) {
                        case 'commit': request = await this.apiBlank.commit(this.item.id)
                            break;
                        case 'rollback':request = await this.apiBlank.rollback(this.item.id)
                            break;
                        default: console.log('Не выбран тип операции')
                            //Здесь находятся инструкции, которые выполняются при отсутствии соответствующего значения
                            //statements_def
                            break;
                    }
                    await this.$refs.listTable.initialize(this.propsModel, this.propsDataRequest, this.propsFilter);
                    await this.$refs.listTable.close();
                    this.$forceUpdate();
                } catch (e) {
                    if (typeof e.response.data === 'object') {
                        Swal.fire(
                                e.response.data.title,
                                e.response.data.detail,
                                'error'
                        )
                    } else {
                        Swal.fire(
                                'Не обработанная ошибка',
                                e.response.data,
                                'error'
                        )
                    }
                }
                this.load = false;
                return request;
            },
        }
    }
</script>

<style scoped>

</style>