<template>
  <v-dialog v-model="dialog" persistent
            max-width="900">
    <v-card>
      <v-card-actions>
        <v-card-title class="text-h5">{{ name }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!sectionType" color="primary" outlined @click="sheet = true">Заполнить</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="close">Отмена</v-btn>
        <v-btn :loading="downloadingAll" color="warning" depressed @click="confirmAll">Скачать все</v-btn>
        <v-btn :loading="uploading" color="success" depressed @click="confirm">Загрузить</v-btn>
      </v-card-actions>
      <v-card-text>
        <v-select
            v-if="!fileType"
            :items="filter.filter(i => i.text)"
            v-model="type"
            label="Тип файла"
            item-text="text"
            item-value="id"
        ></v-select>
        <v-file-input
            v-model="file"
            label="Файл"
            outlined
        ></v-file-input>
      </v-card-text>
      <app-photo-preview></app-photo-preview>
      <v-bottom-sheet
          v-model="sheet"
          persistent
      >
        <v-sheet
            class="text-center"
        >
          <v-btn
              class="mt-6"
              text
              color="error"
              @click="sheet = !sheet"
          >
            Закрыть
          </v-btn>
          <app-list-table v-on:select="value => copyToClaim(value)"
                          :props-model="'PrePhoto'" :props-data-request="'PrePhoto'"
                          :props-select-mode="true"></app-list-table>
        </v-sheet>
      </v-bottom-sheet>
      <v-card
          class="overflow-y-auto"
          max-height="400"
      >
        <v-data-table
            :headers="headers"
            :items="lists"
            hide-default-footer
            class="elevation-1 pa-4"
            @page-count="pageCount = $event"
        >
          <template v-slot:item.thumb="{ item }">
            <app-avatan-permission size="100" tile :id="item.id"></app-avatan-permission>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn :loading="downloading == item.id" class="mr-2" small @click="downloadImage(item.id)">Скачать</v-btn>
            <v-btn :loading="deleting == item.id" color="error" small @click="downloadDell(item.id)">Удалить</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import {apiAttachment} from "@/api/api-attachment";
import AppAvatanPermission from "@/views/widget/AppAvatanPermission";
import AppPhotoPreview from "@/views/widget/AppPhotoPreview";

export default {
  name: "AppDownloadToType",
  components: {
    AppListTable: () => import('./AppListTable'),
    AppAvatanPermission,
    AppPhotoPreview
  },
  props: {
    dataList: {
      type: [Array]
    },
    fileType: Boolean,
    downloadingAll: Boolean,
    uploading: Boolean
  },
  data: () => ({
    sectionType: 0,
    name: '',
    dialog: false,
    type: null,
    file: null,
    id: null,
    filter: [],
    /** @type {{id:number}[]} */
    lists: [],
    imgList: [],
    benched: 0,
    headers: [
      { text: 'ЭСКИЗ', value: 'thumb', sortable: false, },
      { text: 'Названия', value: 'filename', sortable: false, },
      { text: 'Вид', value: 'attachmentTypeName', sortable: false, },
      { text: 'Действия', value: 'actions', sortable: false, width: '230px'  },
    ],
    sheet: false,
    downloading: null,
    deleting: null,
  }),
  computed: {
    items() {
      return Array.from({length: this.length}, (k, v) => v + 1)
    },
    length() {
      return 7000
    },
  },
  created() {
    if (this.fileType) {
      this.type = 4;
      delete this.headers[0];
    }
    this.apiAttachment = new apiAttachment();
  },
  watch: {
    dataList (v) {
        console.log(v);
        this.lists = v;
    },
    lists: {
        deep: true,
        async handler(val) {
          for (let i = 0; i < this.lists.length; i++) {
            let nameType = this.filter.filter(v => v.id == this.lists[i].attachmentType);
            this.lists[i].attachmentTypeName = nameType.length > 0 ? nameType[0].name : 'Тип не найден';
          }
          // for (let i = 0; i < this.lists.length; i++) {
          //   this.lists[i].thumb = await this.getImage(this.lists[i].id)
          // }
        }
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', {id: this.id, type: this.type, file: this.file, sectionType: this.sectionType})
    },
    confirmAll() {
      this.$emit('confirm-all', this.id)
    },
    copyToClaim(value) {
      this.$emit('copy-to-claim', [value.id, this.id])
      this.sheet = false
    },
    close() {
      this.dialog = false;
    },
    /**
     *
     * @param filter[]
     * @param id:string - Идентификатор записи
     * @param name
     * @param sectionType
     */
    async showDownload({ id, filter, name, sectionType }) {
      let attachmentTypes = (await this.apiAttachment.getAttachmentTypes(sectionType)).data

      filter.forEach((item) => {
        item.text = attachmentTypes[item.id]
      })

      console.log(filter)
      this.filter = filter

      this.sectionType = sectionType;
      this.name = name;
      this.id = id;
      this.dialog = true;
      this.file = null;
      if (!this.fileType) {
        this.type = null;
      }
    },
    async downloadDell(id) {
      this.deleting = id
      let data = await this.apiAttachment.attachmentDelete(id);
      console.log(data);
      this.$emit('dataEvent', this.id);
    },
    async downloadImage(id) {
      this.downloading = id
      let file = await this.apiAttachment.getAttachmentDownload(id);
      this.downloading = null
      file = file.data;
      let blobFile = apiAttachment.b64toBlob(file.fileContents, file.contentType, file.fileDownloadName);
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blobFile);
      link.target = '_blank';
      link.download = file.fileDownloadName;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
    }
  }
}
</script>

<style scoped>

</style>