var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"deep-purple accent-4","top":true,"right":true,"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Закрыть ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"900"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.textAlert))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.close}},[_vm._v("Нет")]),_c('v-btn',{attrs:{"color":"success","depressed":""},on:{"click":_vm.confirm}},[_vm._v("Да")]),_c('v-spacer')],1)],1)],1),_c('app-form-widget',{attrs:{"model-value":_vm.modelData},on:{"save":_vm.save},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"small":"","depressed":"","color":"blue"},on:{"click":function($event){return _vm.saveAndSpend(item)}}},[_vm._v("Сохранить и провести ")]),(_vm.lastAddedItem || _vm.saving)?_c('v-btn',{attrs:{"loading":_vm.saving,"small":"","depressed":"","color":"warning"},on:{"click":function($event){return _vm.openFullForm(item)}}},[_vm._v("Полная форма ")]):_vm._e()]}}]),model:{value:(_vm.dialogController),callback:function ($$v) {_vm.dialogController=$$v},expression:"dialogController"}}),(_vm.progress)?[_c('app-list-table',{ref:"listTable",attrs:{"props-model":_vm.propsModel,"props-data-request":_vm.propsDataRequest,"props-filter":_vm.propsFilter,"props-filter-table":_vm.FilterTable,"props-mutation-data":[_vm.mutationCheckListsDescription]},on:{"edited-item-event":_vm.editedItemEvent},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-btn',{staticClass:"mb-2",staticStyle:{"margin-left":"10px"},attrs:{"color":"warning","dark":""},on:{"click":_vm.showModelSimilarDocCheck}},[_vm._v(" Добавить по образцу ")])]},proxy:true},{key:"form",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
return [(_vm.load)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):[(_vm.editedItem)?[_c('v-btn',{staticStyle:{"margin-left":"10px","color":"white"},attrs:{"small":"","depressed":"","color":"indigo"},on:{"click":function($event){return _vm.commitDoc(item)}}},[_vm._v(" Записи документа ")]),(_vm.editedItem.headerId == null)?_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.commit(_vm.editedItem)}}},[_vm._v("Не проведено ")]):_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.rollback(_vm.editedItem)}}},[_vm._v("Проведено ")])]:_vm._e()],_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"small":"","depressed":"","color":"blue"},on:{"click":function($event){return _vm.saveAndSpend(item, edit)}}},[_vm._v("Сохранить и провести")])]}},{key:"btn",fn:function(ref){
var item = ref.item;
return [_c('btn-download',{attrs:{"item":item,"event":_vm.downloadFile}}),(_vm.load)?_c('v-progress-circular',{attrs:{"size":15,"width":1,"indeterminate":"","color":"primary"}}):[(item.headerId == null)?_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.commit(item)}}},[_vm._v("Не проведено ")]):_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.rollback(item)}}},[_vm._v("Проведено ")])]]}}],null,false,1850360593)})]:_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }