<template>
  <v-avatar
    v-bind="$attrs"
    @click="getFullImage"
    style="cursor: pointer; position: relative"
  >
    <v-progress-circular
      v-if="loading"
      class="loader"
      :size="50"
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <img :src="src" alt="thumbnail" />
  </v-avatar>
</template>

<script>
import { apiAttachment } from "@/api/api-attachment";

export default {
  name: "AppAvatanPermission",
  props: ["id"],
  data() {
    return {
      src: null,
      loading: false,
    };
  },
  created() {
    this.apiAttachment = new apiAttachment();
    this.getPreview(this.id);
  },
  methods: {
    async getPreview(id) {
      let thumb = await this.apiAttachment.getAttachmentThumb(id);
      thumb = thumb.data;
      let blobFile = apiAttachment.b64toBlob(
        thumb.fileContents,
        thumb.contentType,
        thumb.fileDownloadName
      );

      this.src = URL.createObjectURL(blobFile);
    },
    async getFullImage() {
      this.loading = true;
      let thumb = await this.apiAttachment.getAttachmentDownload(this.id);
      thumb = thumb.data;
      let blobFile = apiAttachment.b64toBlob(
        thumb.fileContents,
        thumb.contentType,
        thumb.fileDownloadName
      );
      let url = URL.createObjectURL(blobFile);
      this.$root.$emit("getFullPhoto", { url, name: thumb.fileDownloadName });
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(50%, 50%);
  position: absolute;
}
</style>
