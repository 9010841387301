/**
 * AppGlobalHelper Клас в котором вынесенные основыные функции
 */
export class Agh {
  /**
   * Поиск значения без учета регистра
   * @param objectValue
   * @param name
   * @return {number}
   */
  static getIndex(objectValue, name) {
    let key = Object.keys(objectValue).map(v => v.toUpperCase()).indexOf(name.toUpperCase());
    if (key === -1)
      return  -1;
    return Object.keys(objectValue)[key];
  }
  static firstCharLowCase(string) {
    if (string == null || string.length === 0) {
      return '';
    }
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  static firstUpperCase(string) {
    if (string == null || string.length === 0) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}