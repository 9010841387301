<template>
  <span>
    <v-progress-circular
        class="loader"
        v-if="load"
        :size="32"
        :width="1"
        indeterminate
        color="primary"
    ></v-progress-circular>
    <v-btn
        v-else
        style="margin-left: 10px"
        x-small
        fab
        color="indigo"
        @click="downloadFile"
    >
      <v-icon color="white">mdi-file-download-outline</v-icon>
    </v-btn>
  </span>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "btnDownload",
  data() {
    return {
      load: false
    }
  },
  props: {
    item: null,
    event: null
  },
  methods: {
    async downloadFile() {
      if (this.event == null)
        Swal.fire(
            'Ошибка интерфейса',
            'Не переданн event',
            'error'
        )
      if (this.item != null) {
        this.load = true;
        try {
          await this.event(this.item);
        } catch {}
      }
      this.load = false;
    }
  }
}
</script>

<style scoped lang="scss">
  .loader {
    margin: 0 5px;
  }
</style>