<template>
    <v-container class="container--fluid">
        <div slot="widget-content">
            <div id="app">
                <v-app id="inspire">
                    <v-app id="inspire">
                        <app-list-table v-if="component === 'AppListTable'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></app-list-table>
                        <list-table-blank v-if="component === 'ListTableBlank'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></list-table-blank>
                        <list-table-doc v-if="component === 'ListTableDoc'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></list-table-doc>
                        <list-table-excel v-if="component === 'ListTableExcel'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></list-table-excel>
                        <list-table-claim v-if="component === 'ListTableClaim'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></list-table-claim>
                        <list-table-file v-if="component === 'ListTableFile'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></list-table-file>
                        <list-table-certificate v-if="component === 'ListTableCertificate'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></list-table-certificate>
                        <list-table-check-list v-if="component === 'ListTableCheckList'" :props-filter="filter" :props-model="model" :props-data-request="dataRequest"></list-table-check-list>
                    </v-app>
                </v-app>
            </div>
        </div>
    </v-container>
</template>

<script>
    import AppListTable from "../../widget/AppListTable";
    import ListTableBlank from "./ListTableBlank";
    import ListTableExcel from "./ListTableExcel";
    import ListTableClaim from "./ListTableClaim";
    import ListTableDoc from "./ListTableDoc";
    import ListTableFile from "./ListTableFile";
    import ListTableCertificate from "./ListTableCertificate";
    import ListTableCheckList from "./ListTableCheckList";
    export default {
        name: "ListTable",
        components: {
            ListTableCheckList,
            ListTableFile,
            AppListTable,
            ListTableBlank,
            ListTableExcel,
            ListTableClaim,
            ListTableDoc,
            ListTableCertificate
        },
        data: () => ({
            model: '',
            dataRequest: '',
            filter: '',
            component: 'AppListTable',
        }),
        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
            '$attrs.model': {
                handler(val){
                    this.name = 'Загрузка...'
                    this.headers = [];
                    this.desserts = [];
                    this.model = val;
                    this.dataRequest = this.$attrs.dataRequest;
                    this.filter = {filter: this.$attrs.filter};
                    console.log('$attrs.model' + JSON.stringify(this.filter));
                },
                deep: true
            },
            '$attrs.component': {
                handler(val){
                    if (val != null)
                        this.component = val;
                    else
                        this.component = 'AppListTable';
                    console.log(this.component);
                },
                deep: true
            }
        },
        created() {
            if (this.$attrs.component != null)
                this.component = this.$attrs.component;
            else
                this.component = 'AppListTable';
            this.model = this.$attrs.model;
                this.filter = {filter: this.$attrs.filter};
            this.dataRequest = this.$attrs.dataRequest;
        },
    }
</script>

<style >
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td {
        font-size: .675rem;
        height: 48px;
    }
</style>