<template>
  <div>
    <template v-if="index !== 0">
      <template
          v-if="header.groupLine != null && header.groupLine !== editedItem[index - 1].groupLine">
        <b>{{ header.groupLine }}</b>
      </template>
    </template>
    <v-row>
      <template v-if="index !== 0">
        <template
            v-if="header.groupGrid != null && header.groupGrid !== editedItem[index - 1].groupGrid">
          <v-col cols="6">
            <p>{{ header.groupGrid }}</p>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="6">
          </v-col>
        </template>
      </template>
      <v-col :cols="header.groupGrid == null ? 12 : 6" v-if="header.type == 'select'">
        <v-text-field
            v-on:click:prepend="() => {
                sheet = true;
            }"
            clearable
            @click:clear="clearEvent"
            v-model="content.value"
            :label="header.text"
            prepend-icon="mdi-table"
            outlined
            readonly
        >
        </v-text-field>
        <v-bottom-sheet
            v-model="sheet"
            persistent
        >
          <v-sheet
              class="text-center"
          >
            <v-btn
                class="mt-6"
                text
                color="error"
                @click="sheet = !sheet"
            >
              Закрыть
            </v-btn>
            <app-list-table v-on:select="(valueData) => selectEvent(valueData, header)"
                            :props-model="header.modelTable" :props-data-request="header.modelTable"
                            :props-select-mode="true"></app-list-table>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
      <v-col :cols="header.groupGrid == null ? 12 : 6" v-if="header.type === 'enum'">
        <v-autocomplete
            @change="handleInput"
            v-model="content"
            :items="header.options"
            :label="header.text"
            :prefix="header.prefix"
            :suffix="header.postfix"
            outlined
        >
        </v-autocomplete>
      </v-col>
      <v-col :cols="header.groupGrid == null ? 12 : 6" v-if="header.type === 'parentSelect'">
        <v-autocomplete
            @input="handleInput"
            v-model="content"
            :items="attributeFilterObject.select"
            :label="header.text"
            :disabled="attributeFilterObject.disabled"
            outlined
        >
        </v-autocomplete>
      </v-col>
      <v-col :cols="header.groupGrid == null ? 12 : 6" v-if="header.type === 'textarea'">
        <p>{{ header.text }}</p>
        <v-text-field
            :disabled="header.disabled"
            @input="handleInput"
            v-model="content"
            :rules="header.textType"
            single-line
            :prefix="header.prefix"
            :suffix="header.postfix"
        ></v-text-field>
      </v-col>
      <v-col :cols="header.groupGrid == null ? 12 : 6" v-if="header.type === 'text'">
        <p v-if="header.text != ''">{{ header.text }}</p>
        <v-text-field
            :disabled="header.disabled"
            @input="handleInput"
            v-model="content"
            :rules="header.textType"
            :prefix="header.prefix"
            :suffix="header.postfix"
            outlined
        ></v-text-field>
      </v-col>
      <v-col :cols="header.groupGrid == null ? 12 : 6" v-if="header.type === 'dateTime'">
        <p>{{ header.text }}</p>
        <datetime-picker
            :disabled="header.disabled"
            @input="handleInput"
            v-model="content"
            :datetime="header.value"
            clearText="отмена"
            okText="выбрать"
            date-format="dd.MM.yyyy"
            time-format="HH:mm:ss"
        >
          <template v-slot:dateIcon>
            <v-icon>дата</v-icon>
          </template>
          <template v-slot:timeIcon>
            <v-icon>время</v-icon>
          </template>
        </datetime-picker>
      </v-col>
    </v-row>
    <template v-if="index + 1 < editedItem.length">
      <template v-if="header.groupLine !== editedItem[index + 1].groupLine">
        <v-divider></v-divider>
      </template>
    </template>
    <template v-if="index + 1 < editedItem.length">
      <template v-if="header.groupGrid !== editedItem[index + 1].groupGrid">
        <v-divider class="pt-5"></v-divider>
      </template>
    </template>
  </div>
</template>

<script>
import DatetimePicker from "../../views/widget/DatetimePicker";
export default {
  name: "AppFormWidgetItem",
  components: {
    AppListTable: () => import('./AppListTable'),
    DatetimePicker
  },
  props: {
    index: [Number],
    header: [Object],
    editedItem: null,
    attributeFilterObject: {
      type: [Object, Array],
      default() {
        return {
          select: [],
          disabled: false
        };
      }
    },
    value: null,
  },
  data() {
    return {
      content: this.value,
      sheet: false
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.content = v;
      }
    }
  },
  methods: {
    clearEvent(v) {
      this.content = {
        key: '',
        value: ''
      };
      this.$emit('input', this.content)
    },
    selectEvent(value, header) {
      this.sheet = false;
      if (header.options.filter(value => value.id === value.id) > 0) {
        this.content = {
          key: value.id,
          value: value.name
        };
      } else {
        this.content = {
          key: value.id,
          value: value.name
        };
      }
      this.$emit('input', this.content)
    },
    handleInput (e) {
      this.$emit('input', this.content)
    }
  }
}
</script>

<style scoped>

</style>