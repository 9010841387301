import { render, staticRenderFns } from "./AppTableItem.vue?vue&type=template&id=80c69d14&scoped=true&"
import script from "./AppTableItem.vue?vue&type=script&lang=js&"
export * from "./AppTableItem.vue?vue&type=script&lang=js&"
import style0 from "./AppTableItem.vue?vue&type=style&index=0&id=80c69d14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80c69d14",
  null
  
)

export default component.exports