import http from "./http-common";
import store from "../store";
import GeneralAPI from ".";

// eslint-disable-next-line no-unused-vars
export const TypeFilter = {
    CHECK: 0,
    DATE_TIME: 1,
};

/**
 * @param {apiDirectory} apiDirectory
 */
export class apiDirectory extends GeneralAPI {
    _model = ''
    _dataRequest = ''
    _basicAuth = ''
    _modelData = null;
    constructor(model, dataRequest) {
        super()
        this._model = model;
        this._dataRequest = dataRequest;
        this._basicAuth = `Bearer ${store.getters.token}`;
    }
    setModel(model) {
        this._modelData = model;
    };

    /**
     * Получить список записей
     */
    getAll(page, rows, order, filter) {
        return http.post(`/${this._dataRequest}/list`, Object.assign({
            "page": page,
            "rows": rows,
        }, filter, order), {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    /**
     * Создать запись
     */
    create(data) {
        return http.post(`/${this._dataRequest}`, data, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    /**
     * Изменить запись
     */
    update(data) {
        return http.put(`/${this._dataRequest}`, data, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    /**
     * Получить запись
     */
    get(id) {
        return http.get(`/${this._dataRequest}/${id}`, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    /**
     * Получить запись для атрибута
     */
    getDataSource(url, method) {
        console.log('method', method);
        if (method === 'post') {
            return http.get(`/${url}`,{
                "page": 1,
                "rows": 1000,
            }, {
                headers: {
                    'Authorization': this._basicAuth
                }
            }).catch((error) => {
                    this.errorCatch(error);
                    return Promise.reject(error)
                }
            );
        } else if (method === 'get') {
            return http.get(`/${url}`, {
                headers: {
                    'Authorization': this._basicAuth
                }
            }).catch((error) => {
                    this.errorCatch(error);
                    return Promise.reject(error)
                }
            );
        } else {
            console.error('Метод не определен api-directory')
            return -1;
        }
    }
    /**
     * Удалить запись
     */
    async delete(id) {
        return http.delete(`/${this._dataRequest}/${id}`, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    /**
     * Получение метаданных об объекте данных
     */
    getModel() {
        return http.get(`/utils/Metadata/${this._model}Model`, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    /**
     * Получение данных для select
     */
    getGostsCombo(ComboValues, id) {
        return http.get(`${ComboValues}/${id}`, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    getFileType(nav) {
        return http.get(`/utils/BaseFile/${nav}`, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
    getCertificateType() {
        return http.get(`/LaboratoryStaff/all`, {
            headers: {
                'Authorization': this._basicAuth
            }
        }).catch((error) => {
                this.errorCatch(error);
                return Promise.reject(error)
            }
        );
    }
}