import Swal from "sweetalert2"
import store from "../store"

export default class GeneralAPI {
  async errorCatch(error) {

    if (error.response.status === 401) {
      store.dispatch('LogOut')
      location.reload()
    }

    let response = error.response.data
    let title = response.title || 'Error'
    let message = "Unexpected error"

    const formatError = (name, value) => {
      let [error, path] = value.split('Path')
      path = 'Path ' + path
      return `<b>[${name}]</b> - <span style="color:red">${error}</span>${path}<br/>`
    }

    const formatErrors = errors => {
      let result = ""
      Object.entries(errors).forEach(([key, value]) => {
        value.forEach(msg => {
          result += formatError(key, msg)
        })
      })
      return `<span style="font-size:.9rem">${result}</span>`
    }

    if (response.errors != null) {
      message = formatErrors(response.errors)
    } else if (response instanceof Blob) {
      try {
        let msg = JSON.parse(await response.text()).title
        if (msg) message = msg
      } catch { }
    }

    Swal.fire(
      title,
      message,
      'error'
    )
  }
}